/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { Toaster } from 'react-hot-toast';

import './App.scss';

const Login = React.lazy(() => import('./components/auth/Login'));
const Auth = React.lazy(() => import('./components/auth/Auth'));
const Onboarding = React.lazy(() => import('./components/auth/onboarding/Onboarding'));
const PaymentSucess = React.lazy(() => import('./components/common/PaymentSuccess'));
const PaymentError = React.lazy(() => import('./components/common/PaymentError'));
const PublicWrapper = React.lazy(() => import('./components/roadmaps/public/PublicWrapper'));
const DashboardWrapper = React.lazy(() => import('./components/common/DashboardWrapper'));
const ForgotPassword = React.lazy(() => import('./components/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./components/auth/ResetPassword'));

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse">
			<Spinner animation="border" variant="success" />
		</div>
	</div>
);

const Redirect = ({ to }) => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate(to);
	});
	return null;
}


const App = () => (
	<div className='app-container'>
		<React.Suspense fallback={loading}>
			<Routes>
				<Route path="/admin/*" element={<DashboardWrapper />} />
				{/* public routes */}
				<Route path="/" element={<PublicWrapper />} />
				<Route path="/:feature" element={<PublicWrapper />} />
				<Route path="/:feature/:slug" element={<PublicWrapper />} />
				<Route path="/:feature/preview/:slug" element={<PublicWrapper />} />


				<Route path="/payment-sucess" element={<PaymentSucess />} />
				<Route path="/payment-error" element={<PaymentError />} />

				{/* old path being redirected to new path */}
				<Route path="/company/:companyId/:feature" element={<PublicWrapper oldurl />} />
				<Route path="/company/:companyId/:feature/:slug" element={<PublicWrapper oldurl />} />

				<Route path="/signup/appsumo" element={<Onboarding />} />
				<Route path="/signup/appsumo/:code" element={<Onboarding />} />

				<Route path="/login" element={<Login />} />
				<Route path="/signup" element={<Onboarding />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/reset-password" element={<ResetPassword />} />
				<Route path="/workspace/register" element={<Onboarding />} />
				<Route path="/auth" element={<Auth />} />

				{/* free tools */}
				{/* <Route path="/tools" element={<Tools />} /> */}
			</Routes>
		</React.Suspense>
		<Toaster position="bottom-right" />
	</div>
)

export default App;
